// ANTARGAZ COLORS
// Primary colors
$primary-color: #54D52B;
$primary-color-medium: #528342;
$primary-color-light: #F0F8E8;

// Secondary colors
$secondary-color: #0D446B;
$secondary-color-medium:  #07273E;
$secondary-color-light: #EEF1F6;

// Tertiary colors
$tertiary-color: #F00036;

// Black, white, grey
$black: #000000;
$gray: #707070;
$gray-demi: #AAAAAA;
$gray-medium: #DADADA;
$gray-light: #F2F2F2;
$gray-extra-light: #F7F7F7;
$white: #FFFFFF;

// Contextual: Fonts
$font-color: $black;
$font-color-medium: $gray;
$font-color-light: $gray-demi;
$font-color-disabled: $font-color-medium;

// Contextual: Backgrounds
$background: $primary-color-light;

// Contextual: Borders
$border-color: $gray-medium; // Borders and rules
$border-color-active: $secondary-color; // Active border
$border-color-light: $gray-light;

// UI
$ui-red: #FFB4C2;
$ui-orange: #FCE7AC;
$ui-green: #E6F1CE;
$ui-blue: #CAEDF3;

// Workspace
$sandbox-fnt-color: #15241F;
$sandbox-border-color: #979797;
$sandbox-header: #F3F3F3;
$sandbox-header-dark: #262525;

// colors prettyprint
$prettyprint-atn: #008080;
$prettyprint-atv: #DD1144;
$prettyprint-tag: #000080;
$prettyprint-pln: #616367;

// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

//img path
$imgPath: '../img';


//colors
$darkColor: #000;
$lightColor: #fff;

$brandColor: $secondary-color;
$brandColorAlt: $primary-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;

$text-color: $font-color;
$title-color: $secondary-color;

// Navbar
$navbar-default-bg: $white;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

body {
  background: $lightColor !important;
}

.row.fullrow {
  max-width: 100%;
}
