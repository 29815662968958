.article-about {
   @include breakpoint(medium) {
    html.smil & {
      min-height: 500px;
    }
  }
}

.about-1 {
  @include breakpoint(medium) {
    background: url('#{$imgPath}/eco-bg.png') no-repeat center center;
    background-size: cover;
  }
  .padding-section {
    padding-top: 30px;
    padding-bottom: 30px;
    @include breakpoint(medium) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  h1 {
    font-family: $body-font-family;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-size: 40px;
    position: relative;
    line-height: 1.5;
    margin-bottom: 40px;
    @include breakpoint(medium) {
      font-size: 45px;
    }
    span {
      position: relative;
      z-index: 1;
      display: block;
      &:first-child {
        &:before, &:after {
          content: "";
          display: block;
          width: calc(50% - 34px);
          height: 1px;
          background: $brandColorAlt;
          position: absolute;
          bottom: 0;
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
      }
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "&";
      font-size: 115px;
      color: $brandColorAlt;
      font-family: 'Fjalla-regular', Arial, sans-serif;
      opacity: .8;
    }
  }
}

// .illu.salad {
//   z-index: 10;
//   @include breakpoint(medium) {
//     width: 430px;
//     height: auto;
//   }
//   @include breakpoint(large) {
//     width: 550px;
//     height: 175px;
//     display: block;
//     position: absolute;
//     right: 0;
//     bottom: 0;
//     z-index: 15;
//   }
//   @include breakpoint(large) {
//     right: 10%;
//     bottom: 0;
//   }
// }

.about-2 {
  background: url('#{$imgPath}/texture.jpg') repeat-y left top;
  @include breakpoint(medium) {
    background: transparent;
  }
  .padding-section {
    padding-top: 40px;
    padding-bottom: 100px;
    @include breakpoint(medium) {
       padding: 90px 0;
       padding-top: 90px;
       padding-bottom: 90px;
    }
  }
  h1 {
    border-top: 3px solid $lightColor;
    border-bottom: 3px solid $lightColor;
    font-family: $body-font-family;
    font-weight: 700;
    text-transform: uppercase;
    padding: 18px 0 15px 0;
    text-align: center;
    max-width: 80%;
    margin: 0 auto 25px auto;
    @include breakpoint(medium) {
      margin: 0 0 30px 0;
      max-width: 100%;
      border-top-color: $brandColor;
      border-bottom-color: $brandColor;
    }
    span {
      display: block;
      font-size: 30px;
      line-height: 1.5;
      @include breakpoint(medium) {
        font-size: 45px;
      }
      &:first-child {
        font-size: 25px;
        line-height: 1.5;
        @include breakpoint(medium) {
          font-size: 30px;
        }
      }
    }
  }
  .illu {
    position: absolute;
    max-width: 65%;
    display: block;
    transform: translate(-50%, 50%);
    left: 50%;
    bottom: 0;
    @include breakpoint(medium) {
      max-width: 40%;
      right: 0;
      left: auto;
      top: 50%;
      transform: translateY(-50%);
    }
    @include breakpoint(large) {
      max-width: 55%;
    }
  }
}

.about-3 {
  @include breakpoint(medium) {
    background: url('#{$imgPath}/car-bg.png') no-repeat center center;
    // background-size: auto 100%;
    background-size: cover;
  }

  .padding-section {
    padding-top: 40px;
    padding-bottom: 40px;
    @include breakpoint(medium) {
      padding-top: 90px;
      padding-bottom: 90px;
    }
  }

  h1 {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 1.5;
    text-align: center;
    position: relative;
    @include breakpoint(medium) {
      font-size: 45px;
    }
    &:after {
      display: block;
      content: "";
      width: 50px;
      position: absolute;
      bottom: 0;
      height: 1px;
      background: $brandColorAlt;
      left: 50%;
      transform: translateX(-50%);
    }
    span {
      display: block;
    }
    .title {
      font-family: $body-font-family;
      font-weight: 700;
    }
    .subtitle {
      padding: 0 45px;
      font-family: 'Sixcaps-regular', Arial, sans-serif;
      position: relative;
      display: inline-block;
      &:before, &:after {
        content: "";
        display: block;
        height: 15px;
        width: 40px;
        border-top: 2px solid $brandColorAlt;
        border-bottom: 2px solid $brandColorAlt;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }
  }
}
