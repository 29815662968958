.section.form {
  padding: 15px 0;
  background: url('#{$imgPath}/texture.jpg') no-repeat center top;
  background-size: cover;
  > .overflow-hidden {
    overflow: hidden;
  }
  .anchor {
    display: block;
    margin: 40px auto 0 auto;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    background: url('#{$imgPath}/sprite.svg') no-repeat 0 0;
  }
  h1 {
    font-family: $body-font-family;
    color: $lightColor;
    text-transform: uppercase;
    lint-height: 1.5;
    strong {
      color: $lightColor;
      text-align: center;
      display: block;
      font-size: 40px;
      font-weight: 700;
      &:first-child {
        letter-spacing: 8px;
      }
      @include breakpoint(medium) {
        font-size: 45px;
      }
    }
    strong.icon-text {
      display: block;
      position: relative;
      &:before, &:after {
        content: "";
        display: block;
        width: calc(50% - 60px);
        height: 1px;
        background: $brandColorAlt;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
      > span {
        margin: 0 auto;
        display: table;
        font-family: 'Sixcaps-regular', Arial, sans-serif;
        font-size: 27px;
        text-transform: normal;
        background: $brandColorAlt;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        text-align: center;
        letter-spacing: 2px;
        position: relative;
        &:before {
          content: "";
          display: block;
          border-radius: 50%;
          width: 64px;
          height: 64px;
          position: absolute;
          left: 3px;
          top: 3px;
          border: 1px solid $brandColor;
        }
        > span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }

  p {
    color: $lightColor;
    line-height: 30px;
  }

  h2 {
    font-family: $body-font-family;
    font-weight: 700;
    // margin-bottom: 50px;
    margin-bottom:45px;
    color: $title-color;
    padding-top: 10px;
    padding-bottom: 8px;
    border-top: 1px solid $brandColorAlt;
    border-bottom: 1px solid $brandColorAlt;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    // font-size: 36px;
    font-size: 30px;
    @include breakpoint(large) {
      padding-bottom: 10px;
      margin-bottom: 50px;
    }
    i {
      display: block;
      background: $lightColor;
      width: 43px;
      height: 23px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      &:before {
        content: "";
        display: block;
        width: 23px;
        height: 23px;
        background: url('#{$imgPath}/sprite.svg') no-repeat -30px 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &.small {
      display: table;
      margin: 0 auto 20px auto;
      font-size: 20px;
      padding-top: 15px;
    }
  }

  .coins-img {
    display: block;
    position: relative;
    &:before {
      content: "";
      width: 165px;
      height: 92px;
      display: block;
      position: absolute;
      bottom: 15px;
      right: 30px;
      transform: translateX(50%);
      background: url('#{$imgPath}/coins.png') no-repeat left top;
    }
  }

  .block-promo {
    position: relative;
    min-height: 530px;
    &:before {
      content: "";
      display: block;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      position: absolute;
      left: 10px;
      top: 10px;
      border: 1px solid $text-color;
      pointer-events: none;
    }

    &.block-intro {
      position: absolute;
      background: $lightColor;
      width: 100%;
      z-index: 5;
      top: 0;
      bottom: 0;
    }
  }
  .promo-intro {
    width: 100%;
    padding: 55px 45px;
    font-family: $body-font-family;
    background: $lightColor;
    strong {
      text-align: center;
      font-family: $body-font-family;
      font-weight: 700;
      color: $brandColorAlt;
      display: block;
      font-size: 100px;
      line-height: 100px;
      margin-bottom: 40px;
      sup {
        font-size: 30px;
        top: -2.2em;
      }
    }

    span {
      display: block;
      text-align: center;
      background: url('/img/bottle_gaz.png') ;
      margin: 0 auto 50px auto;
      @include breakpoint(medium down) {
        width: 200px;
        height: 207px;
      }
      @include breakpoint (large) {
        width: 222px;
        height: 229px;
      }
      background-size: contain;
    }
  }


  .form-wrapper {
    // display: none;
    padding: 43px 45px;
    font-family: $body-font-family;
    .close {
      cursor: pointer;
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      right: 25px;
      top: 25px;
      &:before, &:after {
        position: absolute;
        content: "";
        display: block;
        width: 2px;
        height: 100%;
        background: $text-color;
        top: 0;
        left: 50%;
      }
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }

    form {
      input {
        font-weight: 400;
        font-size: 20px;
        font-family: $body-font-family;
        padding: 0.3rem 0.5rem 0.5rem 0.5rem;
        border: 1px solid #455d7a;
        border-radius: 2px;
        box-shadow: none;
        background: $lightColor;
        margin: 0 0 .3rem;
      }
      input::-webkit-input-placeholder {
         color: #a2aebc;
      }

      input:-moz-placeholder { /* Firefox 18- */
         color: #a2aebc;
      }

      input::-moz-placeholder {  /* Firefox 19+ */
         color: #a2aebc;
      }

      input:-ms-input-placeholder {
         color: #a2aebc;
      }
    }
  }

  .error {
    color: #da0d4c;
    font-size: 11px;
    letter-spacing: 1px;
    display: none;
    &.form-error {
      margin-top: 0;
      margin-bottom: 3px;
    }
    &.error-recaptcha {
      margin-bottom: 5px;
    }
  }

  .error-submit {
    display: none;
    background: #da0d4c;
    color: $lightColor;
    text-align: center;
    padding: 0 5px;
  }

  .button {
    display: block;
    font-family: $body-font-family;
    font-weight: 700;
    width: 100%;
    margin: 0 auto;
    background: $brandColorAlt;
    text-transform: uppercase;
    color: $lightColor;
    font-size: 20px;
    line-height: 1.5;
    padding: 0.5em 1em 0.5em 1em;
    &:hover {
      background: $lightColor;
      color: $brandColorAlt;
      border: 1px solid $brandColorAlt;
    }
  }

  .checkbox {
    a {
      text-decoration: underline;
      color:inherit;
    }
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;
    i {
      display: block;
      width: 10px;
      height: 10px;
      border: 1px solid $brandColor;
      position: absolute;
      left: 0;
      top: 4px;
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      visibility: hidden;
      &:checked {
        + i {
          background: $brandColor;
        }
      }
    }
    span.label {
      line-height: 19px;
      font-size: 11px;
      font-family: $body-font-family;
      line-height: 14px;
      letter-spacing: 1px;
      display: block;
      background: transparent;
      white-space: normal;
      color: $text-color;
      padding: 0;

    }
  }

  .thx-wrapper {
    padding: 25px 45px 150px 45px;
    /* display: none; */
    .bbq-icon {
      width: calc(100% - 60px);
      position: absolute;
      bottom: 35px;
      left: 50%;
      transform: translateX(-50%);
      &:before {
        content: "";
        display: block;
        width: 66px;
        height: 114px;
        background: $lightColor url('#{$imgPath}/sprite.svg') no-repeat -1px -31px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .line {
        display: block;
        width: 75%;
        height: 1px;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        &:before, &:after {
          content: "";
          width: calc(50% - 45px);
          display: block;
          position: absolute;
          background: $brandColorAlt;
          height: 1px;
          top: 0;
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
      }
    }
    > strong {
      padding-top: 25px;
      padding-bottom: 25px;
      color: $brandColorAlt;
      font-size: 50px;
      font-family: $body-font-family;
      display: block;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      position: relative;
      line-height: 65px;
      &:before, &:after {
        content: "";
        display: block;
        width: 1px;
        height: 25px;
        position: absolute;
        background: $brandColorAlt;
        left: 50%;
        transform: translateX(-50%);
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
    }
    p {
      text-align: center;
      color: $brandColor;
      font-size: 16px;
      padding: 0 30px;
    }
  }
}
.g-recaptcha {
  > div {
    margin: 0 auto 5px auto;
  }
}
// animations

.flip-container {
  perspective: 1000px;
  // display: none;
}
  /* flip the pane when hovered */
  .flip-container.flip .flipper, .flip-container.hover .flipper {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
  }

.flip-container, .front, .back {
  /* width: 320px;
  height: 480px; */
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  -webkit-transition: 0.6s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;

  position: relative;
}

.front, .back {
  transform-style: preserve-3d;
  background: $lightColor;
}

/* hide back of pane during swap */
.back {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.front {
  position: relative;
  -webkit-backface-visibility: hidden;
  top: 0;
  left: 0;
  overflow: hidden;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
