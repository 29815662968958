.sticky {
  padding-bottom: 106px;
  z-index: 2;
  @include breakpoint(1540px) {
    padding-bottom: 97px;
  }
  @include breakpoint(2000px) {
    padding-bottom: 80px;
  }
  @include breakpoint(small only) {
    padding: 30px 0;
    position: relative !important;
    &.is_stuck {
      top: 0 !important;
      + div {
        display: none !important;
      }
    }
  }
  img {
    display: block;
    max-width: 210px;
    margin: 0 auto;
    @include breakpoint(medium) {
      max-width: 260px;
      width: auto;
      height: auto;
      margin: 0 45px 0 auto;
    }
    @include breakpoint(large) {
      max-width: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }
}

.new-icon {
  width: 100px;
  height: 100px;
  display: block;
  background: url('#{$imgPath}/CO2-logo.png') no-repeat center center;
  color: $lightColor;
  position: absolute;
  left: 50%;
  transform: translateX(-130%);
  bottom: 90px;
  background-size: 100% auto;
  padding-top: 35px;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  font-family: 'Fjalla-regular', Arial, sans-serif;
  opacity: 1;
  transition: opacity .1s linear;
  @include breakpoint(medium) {
    transform: translateX(-20%);
    bottom: 160px;
  }
  @include breakpoint(large) {
    width: 153px;
    height: 153px;
    padding-top: 50px;
    transform: translateX(-150%);
  }
  &.hidden {
    opacity: 0;
  }
}

.sticky-nav {
  display: block;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  list-style: none;
  margin: 0;
  &.visible {
    opacity: 1;
  }
  li {
    padding-top: 20px;
    position: relative;
    &:before {
      content: "";
      display: block;
      top: -1px;
      right: 10px;
      transform: translateX(50%);
      width: 6px;
      height: 22px;
      background: $lightColor;
      position: absolute;
    }
    &:first-child {
      padding-top: 0;
      &:before {
        display: none;
      }
    }
    a {
      text-indent: -9999px;
      display: block;
      position: relative;
      height: 20px;
      font-family: $body-font-family;
      &:before {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $lightColor;
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }
      span {
        font-size: 13px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &.active {
      a {
        background: transparent;
        width: auto;
        text-indent: 0;
        border: 4px solid $lightColor;
        border-radius: 25px;
        height: 30px;
        padding-right: 25px;
        margin-right: -5px;
        &:before {
          background: transparent;
          border: 2px solid white;
          width: 25px;
          height: 25px;
          top: -1px;
          right: -1px;
        }
        &:after {
          content: "";
          display: block;
          width: 44px;
          height: 26px;
          position: absolute;
          right: -9px;
          top: -2px;
          box-shadow: inset 12px 0px $lightColor;
          -moz-box-shadow: inset 12px 0px $lightColor;
          z-index: -1;
          border-radius: 50%;
        }
        span {
          display: block;
          height: 100%;
          width: 100%;
          background: $lightColor;
          color: $text-color;
        }
      }
    }
  }
}
