.o-socials {
  background-color: $secondary-color-medium;
  padding: 2rem;
  .m-socials {
    display: flex;
    justify-content: center;
    .a-social {
      margin-right: 4rem;
      img {
        height: 50px;
        border-radius: 50%;
      }
    }
    .a-social:last-child{
      margin: 0;
    }
  }
}