.section.video-slider {
  padding: 15px 0;
  background: url('#{$imgPath}/texture.jpg') no-repeat center top;
  background-size: cover;

  > .overflow-hidden {
    overflow: hidden;
  }

  h1 {
    font-family: $body-font-family;
    color: hotpink;
    text-transform: uppercase;
    line-height: 1.5;

    strong {
      color: $lightColor;
      text-align: center;
      display: block;
      font-size: 40px;
      font-weight: 700;

      &:first-child {
        letter-spacing: 8px;
      }

      @include breakpoint(medium) {
        font-size: 45px;
      }
    }

    strong.icon-text {
      display: block;
      position: relative;

      &:before, &:after {
        content: "";
        display: block;
        width: calc(50% - 60px);
        height: 1px;
        background: $brandColorAlt;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }

      > span {
        margin: 0 auto;
        display: table;
        font-family: 'Sixcaps-regular', Arial, sans-serif;
        font-size: 27px;
        text-transform: normal;
        background: $brandColorAlt;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        text-align: center;
        letter-spacing: 2px;
        position: relative;

        &:before {
          content: "";
          display: block;
          border-radius: 50%;
          width: 64px;
          height: 64px;
          position: absolute;
          left: 3px;
          top: 3px;
          border: 1px solid $brandColor;
        }

        > span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }

  p {
    color: $lightColor;
    line-height: 30px;
  }
}


.section.video-slider {
  .video-carousel {
    min-width: 200px;
    min-height: 200px;
    width: 100%;

    &__inner { }

    &__items {
      width: 100%;
    }

    &__item {
      position: relative;
      overflow: hidden;
      width: 100%;
      max-height: 500px;

      &.slick-slide { }

      iframe {
        min-width: 200px;
        min-height: 200px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.slick-slider {
  position: relative;


  .slick-slide {
    outline: none;
  }

  .slick-track {
    min-height: 200px;
  }

  .slick-prev, .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $primary-color;
    padding: 5px;

    line-height: 0;

    svg {
      width: 26px;
      height: 26px;
      fill: $white !important;
    }

    &:hover {
      cursor: pointer;
      color: $white;

      svg {
        fill: $tertiary-color !important;
      }
    }
  }

  .slick-disabled {
    // Overwrite slick js with important.
    display: none !important;
  }

  .slick-prev {
    left: 0;
    transform: translate(-50%, -50%);

    svg {
      transform: rotate(180deg);
    }
  }

  .slick-next {
    right: 0;
    transform: translate(50%, -50%);
  }
}
