.relative {
  position: relative;
}

p {
  color: $text-color;
  font-family: $body-font-family;
  font-size: 16px;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
p,
span,
strong {
  color: $brandColor;
  &.light {
    color: $lightColor;
  }
  &.medium-light {
    @include breakpoint(medium) {
      color: $lightColor;
    }
  }
  &.large-light {
    @include breakpoint(large) {
      color: $lightColor;
    }
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
p,
span,
strong {
  &.dark {
    color: $title-color;
  }
  &.medium-dark {
    color: $lightColor;
    @include breakpoint(medium) {
      color: $title-color;
    }
  }
  &.large-dark {
    color: $lightColor;
    @include breakpoint(large) {
      color: $title-color;
    }
  }
}

.button {
  transition: color .2s linear, background-color .2s linear;
}

.about-1,  {
  @include breakpoint(medium) {
    position: relative;
    .separator-line {
      content: "";
      display: block;
      width: 1px;
      height: 80px;
      background: $brandColorAlt;
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      left: 20%;
    }
  }
}

.about-3, .section.form {
  @include breakpoint(medium) {
    position: relative;
    .separator-line {
      content: "";
      display: block;
      width: 1px;
      height: 80px;
      background: $brandColorAlt;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
    }
  }
}

.section.form {
  .separator-line {
    display: none;
    @include breakpoint(xlarge) {
      display: block;
    }
  }
}

.row {
  width: 100%;
}

.full-height {
  html.no-smil & {
    height: 100%;
  }
  &.fullheight-section {
    html.no-smil & {
      min-height: 500px;
      height: 100%;
    }
  }
}

.ie-flex-parent {
  html.no-smil & {
    display: flex;
    flex-direction: row;
    .ie-flex-child {
      display: flex;
      min-height: 500px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

}

.row .row {
  margin: 0;
}
