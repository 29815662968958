.intro {
  padding-top: 40px;
  padding-bottom: 0;
  background: url('#{$imgPath}/intro-bg.jpg') no-repeat center bottom;
  background-size: cover;
  position: relative;
  &.article-about {
    @include breakpoint(large) {
      html.smil &, html.no-smil & {
        min-height: 740px;
      }
    }
  }
  @include breakpoint(large) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  h1 {
    max-width: 300px;
    line-height: 1;
    margin: 0 auto;
    color: $lightColor;
    text-align: center;
    text-decoration: none;
    @include breakpoint(medium) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    @include breakpoint(large) {
      padding-top: 100px;
      padding-bottom: 100px;
      top: 50px;
    }
    a {
      display: block;
      text-indent: -9999px;
      width: 30px;
      height: 30px;
      background: url('#{$imgPath}/sprite.svg') no-repeat 0 0;
      margin: 15px auto 0 auto;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
      @include breakpoint(medium) {
        position: relative;
        left: auto;
        transform: none;
        bottom: auto;
      }
    }

    &:before, &:after {
      display: none;
      content: "";
      width: 1px;
      height: 80px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background: $lightColor;
      @include breakpoint(large) {
        display: block;
      }
    }
    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    span, strong {
      display: block;
      color: $lightColor;
      text-transform: uppercase;
      position: relative;
      &:before {
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        background: $lightColor;
        position: absolute;
        bottom: 0;
      }
    }
    span {
      font-size: 50px;
      line-height: 65px;
      font-family: "Now-Black", Arial, sans-serif;
      text-shadow: 2px 0px 1px rgba(0, 0, 0, 1);
    }
    strong {
      font-size: 50px;
      line-height: 1.5;
      font-family: $body-font-family;
      font-weight: 700;
      text-shadow: 2px 0px 1px black;
    }
  }
}
