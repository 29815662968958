// File style for POS locator

.map {
    position: relative;

    .row {
        max-width: 100%;
        position: relative;

        h1 {
            border-bottom: 3px solid $brandColorAlt;
            border-top: 3px solid $brandColorAlt;
            margin: 13px 0 60px;
            position: relative;
            color: $title-color;
            font-family: $body-font-family;
            text-transform: uppercase;
            font-size: 25px;
            line-height: 1.5;
            font-weight: 700;
            padding: 10px 0;
            margin: 0 auto;
            @include breakpoint (medium) {
                font-size: 35px;
                max-width: 400px;
                margin: 0;
            }

            i {
                display: block;
                background: #ffffff;
                position: absolute;
                width: 51px;
                height: 20px;
                top: 0;
                left: 50%;
                transform: translate(-50%,-50%);

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 17px;
                    height: 20px;
                    background: url("/img/shape.png") no-repeat;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }

        .ajax {
            border: solid 1px $brandColor;
        }

        .content {
            top: 50px;
            position: absolute;
            display: block;
            max-width: 80%;
            @include breakpoint(medium down) {
                position: static;
                left: 0!important;
                margin: 50px auto;
            }
            @include breakpoint(medium) {
                max-width: 400px;
            }

            p {
                color: $brandColor;
                position: relative;
                padding: 50px 0 20px 0;

                button {
                    width: 40px;
                    height: 40px;
                    background: $title-color;
                    display: inline-block;
                }
            }

            .form-pos {
                button {
                    width: 40px;
                    height: 40px;
                    background: $title-color;
                    display: inline-block;
                }

                input {
                    width: calc(100% - 50px);
                    display: inline-block;
                    float: left;
                    position: relative;
                    border:solid #455d7a 1px;
                    box-shadow: transparent;
                }
                form {

                  .typeahead__container.backdrop+.typeahead__backdrop, .typeahead__container.filter .typeahead__dropdown, .typeahead__container.hint .typeahead__hint, .typeahead__container.result .typeahead__list {
                      display: block!important;
                      .ps__scrollbar-y-rail {
                        display: block !important;
                      }
                  }

                  .typeahead__container+.typeahead__backdrop, .typeahead__container .typeahead__dropdown, .typeahead__container .typeahead__hint, .typeahead__container .typeahead__list {
                      display: none!important;

                  }
                  div.typeahead__container, div.typeahead__container.cancel{
                    div.typeahead__result.ps.ps--theme_default.ps--active-y{
                      div.ps__scrollbar-y-rail {
                       visibility: hidden !important;

                     }
                    }
                  }
                  div.typeahead__container.cancel.result{
                    div.typeahead__result.ps.ps--theme_default.ps--active-y{
                      div.ps__scrollbar-y-rail {
                       visibility: visible !important;

                     }
                    }
                  }

                  .typeahead__container.cancel.result div.typeahead__result.ps.ps--theme_default.ps--active-y div.ps__scrollbar-y-rail {
                    display: block !important;
                  }

                  .result {
                    position: relative;
                    .typeahead__result {
                      overflow-y: auto;
                      background: #fff;
                      height: 300px;
                      width: 97%;
                      border: solid 1px #c7ced7;
                      margin-top: 2px;
                      position:absolute;
                      display: block;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 11px;
                        height: 11px;
                        top:-6px;
                        z-index: 1;
                        left:60%;
                        border-right: solid #c7ced7 1px;
                        border-bottom: solid #c7ced7 1px;
                        display: block;
                        background: white;
                        transform: rotate(-135deg);
                      }

                      ul {
                        li {
                          position: relative;
                          list-style-type: none;
                          padding: 10px 0;
                          width: 95%;
                          &:not(:last-child) {
                            border-bottom: solid #c7ced7 1px;
                          }

                          a {
                            span {
                              display: block;
                              padding-left: 5px;

                              &.pos-search-label {
                                font-weight: bold;
                                font-family: 'Roboto';
                              }
                              &.pos-search-address, &.pos-search-city{
                                width: auto;
                                display: inline-block;
                                text-align: right;
                              }
                              &.pos-search-code {
                                min-width: auto;
                                display: inline-block;
                                text-align: right;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
            }

        }

        #map {
            height: 575px;

            div.gm-style-iw {
                position: relative;

                h1 {
                    margin: 0 auto;
                    font-family: 'Fjalla-regular';
                    font-size: 18px;
                    text-transform: uppercase;
                    border: 0;
                    // word-break: break-all;
                    min-width: 150px;
                    display: block;
                    text-align: center;

                    &:after {
                        content: "";
                        width: 50px;
                        height: 1px;
                        top: 100%;
                        position: absolute;
                        background: #b3a779;
                        right: 32%;
                    }
                }

                .general-infos {
                    margin: 10px 0 0 16px;

                    span {
                      display: block;
                      font-size: 14px;
                      font-family: 'Dax-regular',Arial,sans-serif;
                      text-align: left;
                      text-transform: lowercase;
                      &::first-letter{
                        text-transform: capitalize;
                    }
                }

                .website {
                  word-break: break-all;
                  color: $brandColorAlt;
                }
                }
                .infos {
                  padding-bottom: 15px;
                  a {
                    word-break: break-all;
                  }
                }
                .tel {
                  display: block;
                  padding-bottom: 10px;
                }
                .address {
                  span {
                    display: inline-block;


                  }
                  .city {
                    padding-left: 5px;
                  }

                }
            }
        }
    }
}
