.footer {
  padding: 40px 0 0 0;
  background: $secondary-color-medium;
  ul {
    text-align: center;
    margin-left: 0;
    li {
      display: inline-block;
      margin: 0 15px;
      a {
        color: $lightColor;
        transition: color .2s linear;
        &:hover {
          color: $brandColorAlt;
        }
      }
    }
  }
}
