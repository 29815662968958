.more-infos {
  background: $brandColorAlt;
  color: $lightColor;
  padding: 30px 0;
  h1 {
    color: $lightColor;
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: $body-font-family;
    font-weight: 700;
    font-size: 35px;
    line-height: 1.5;
    @include breakpoint(medium) {
      font-size: 40px;
    }
  }
  .button {
    display: block;
    background: $lightColor;
    color: $text-color;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: $body-font-family;
    line-height: 1.5;
    padding: 10px 20px 10px 20px;
    &:hover {
      background: $text-color;
      color: $lightColor;
    }
    @include breakpoint(medium) {
      margin-bottom: 0;
      display: inline-block;
      margin-left: 35px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  span {
    display: none;
    color: $lightColor;
    @include breakpoint(medium) {
      display: inline-block;
      margin-left: 35px;
    }
  }
}
