.header {
  padding: 25px 0;
  color: $text-color;
  position: relative;
  .column.medium-shrink.flex-container.align-middle {
    padding-right: 50px;
    @include breakpoint(medium) {
      padding-right: 0.625rem;
    }
  }
  .brand {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 16.66px;
    text-indent: -9999px;
    background: url('#{$imgPath}/cocoon_logo.svg') no-repeat left top;
    background-size: 100% auto;
    @include breakpoint(medium) {
      margin: 0;
    }
    @include breakpoint(large) {
      width: 241px;
      height: 40px;
    }
    .navbar-brand {
      display: block;
    }
  }
  .brand-divider {
    padding: 0 20px;
    font-family: $body-font-family;
    text-transform: uppercase;
    font-weight: 700;
  }
  .brand-sponsor {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 41.66px;
    text-indent: -9999px;
    background: url('#{$imgPath}/logo-antargaz.svg') no-repeat left top;
    background-size: 100% auto;
    @include breakpoint(medium) {
      margin: 0;
    }
    @include breakpoint(large) {
      width: 120px;
      height: 50px;
    }
    .navbar-brand-sponsor {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  .burger {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 34px;
    cursor: pointer;
    &:before, &:after {
      content: "";
      width: 100%;
      height: 2px;
      display: block;
      background: $brandColorAlt;
      position: absolute;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    span {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 100%;
      background: $brandColorAlt;
    }
    span, &:after, &:before {
      transition: all .2s linear;
    }
    &.active {
      &:before {
        top: 50%;
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
        bottom: auto;
        top: 50%;
      }
      span {
        display: none;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    font-family: $body-font-family;
  }
  .navigation {
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background: $lightColor;
    z-index: 100;
    &.opened {
      display: block;
    }
    @include breakpoint(medium) {
      width: auto;
      top: auto;
      position: static;
      display: flex !important;
      align-self: center;
    }
    ul {
      @include breakpoint(medium) {
        align-self: center;
      }
      li {
        position: relative;
        padding: 20px 0;
        @include breakpoint(medium) {
          padding: 0;
          align-self: center;
          margin-left: 25px;
        }
        @include breakpoint(large) {
          margin-left: 45px;
        }
      }
      &.main {
        li {
          &:before {
            content: "";
            display: block;
            width: 80px;
            height: 1px;
            position: absolute;
            background: #455d7a;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            @include breakpoint(medium) {
              display: none;
            }
          }
          &:first-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
    a {
      color: $text-color;
      font-size: 25px;
      line-height: 15px;
      @include breakpoint(medium) {
        font-size: 16px;
        line-height: 16px;
      }
    }
    .flex-container {
      display: block;
      @include breakpoint(medium) {
        display: flex;
      }
    }
    .button {
      margin: 0;
      padding: 7px 30px 9px 30px;
      background: transparent;
      color: $tertiary-color;
      //@todo: change here!!
      border: solid 2px $primary-color;
      transform: transition(all .2s linear);
      &:after {
        display: inline-block;
        content: "";
        width: 10px;
        height: 10px;
        border-top: 2px solid $tertiary-color;
        border-right: 2px solid $tertiary-color;
        transform: rotate(45deg) translateX(-50%);
        margin-left: 10px;
        @include breakpoint(medium) {
          transform: rotate(45deg);
          margin-left: 5px;
        }
      }
      &:hover {
        background: $primary-color;
        color: $lightColor;
        &:after {
          border-top-color: $lightColor;
          border-right-color: $lightColor;
        }
      }
    }
    .language-switcher {
      li {
        display: inline-block;
        padding: 0 0 20px 10px;
        position: relative;
        &:before {
          content: "-";
          left: 0;
          display: block;
          position: absolute;
          top: 0;
        }
        &:first-child {
          @include breakpoint(small only) {
            padding: 0 0 20px 0;
          }
          &:before {
            display: none;
          }
        }
        @include breakpoint(medium) {
          padding: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: $darkColor;
          display: flex;
          &.active {
            display: none;
          }
          a {
            display: block;
            margin-top: -4px;
            line-height: 16px;
            text-align: center;
            color: $lightColor;
          }
        }
      }
    }
  }
}
