.header {
    &.admin {
        li {
            display: inline-block;
        }
        .navigation, .navbar-nav {
            display: inline-block !important;
        }
        .dropdown-menu, .dropdown-toggle {
            display: inline-block;
            margin-left: 25px;
        }

        .dropdown-menu {
            a {
                padding: 5px 10px 10px 10px;
                background: $text-color;
                color: $lightColor;
            }
        }

        .dropdown-toggle {
            color: #e81c34;
            padding: 5px 10px 10px 10px;
            border: solid 2px #89b917;
        }
    }
}

.admin-content {
    margin-top: 75px;
    font-family: 'Dax-medium', Arial, sans-serif;
    h1 {
        font-size: 2rem;
        text-align: center;
        font-family: 'Fjalla-regular', Arial, sans-serif;
    }
}

.add-button {
    background: $brandColorAlt;
    color: $lightColor;
    padding: 5px 10px 10px 10px;
    display: inline-block;
}
