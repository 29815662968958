
// Fonts
@import "partials/fonts";

// Libraries
@import "partials/libraries";

// Variables
@import "partials/base",
"partials/mixins";

//partials
@import "partials/reusable-elements",
        "partials/map",
        "partials/admin",
        "partials/header",
        "partials/form",
        "partials/intro",
        "partials/more-infos",
        "partials/about",
        "partials/sticky",
        "partials/video-slider",
        "partials/footer",
        "partials/perfect-scrollbar",
        "partials/socials";
