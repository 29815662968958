@font-face {
    font-family: 'Sixcaps-regular';
    src: url('../fonts/sixcaps-webfont.woff2') format('woff2'),
         url('../fonts/sixcaps-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Fjalla-regular';
    src: url('../fonts/fjallaone-regular-webfont.woff2') format('woff2'),
         url('../fonts/fjallaone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
  font-family: 'Dax-medium';
  src: url('../fonts/DaxOT-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/DaxOT-Medium.otf')  format('opentype'),
	     url('../fonts/DaxOT-Medium.woff') format('woff'), url('../fonts/DaxOT-Medium.ttf')  format('truetype'), url('../fonts/DaxOT-Medium.svg#DaxOT-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Dax-regular';
  src: url('../fonts/DaxOT-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/DaxOT-Regular.otf')  format('opentype'),
	     url('../fonts/DaxOT-Regular.woff') format('woff'), url('../fonts/DaxOT-Regular.ttf')  format('truetype'), url('../fonts/DaxOT-Regular.svg#DaxOT-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nowalt';
  src: url('../fonts/nowalt-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/nowalt-regular.woff') format('woff'),
  url('../fonts/nowalt-regular.ttf')  format('truetype'),
  url('../fonts/nowalt-regular.svg#DaxOT-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nowalt';
  src: url('../fonts/nowalt-medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/nowalt-medium.woff') format('woff'),
  url('../fonts/nowalt-medium.ttf')  format('truetype'),
  url('../fonts/nowalt-medium.svg#DaxOT-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nowalt';
  src: url('../fonts/nowalt-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/nowalt-bold.woff') format('woff'),
  url('../fonts/nowalt-bold.ttf')  format('truetype'),
  url('../fonts/nowalt-bold.svg#DaxOT-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nowalt';
  src: url('../fonts/nowalt-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/nowalt-bold.woff') format('woff'),
  url('../fonts/nowalt-bold.ttf')  format('truetype'),
  url('../fonts/nowalt-bold.svg#DaxOT-Regular') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nowalt';
  src: url('../fonts/nowalt-black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/nowalt-black.woff') format('woff'),
  url('../fonts/nowalt-black.ttf')  format('truetype'),
  url('../fonts/nowalt-black.svg#DaxOT-Regular') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Now-Black';
  src: url('../fonts/now-black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
