// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group off-canvas
////

/// Width of a left/right off-canvas panel.
/// @type Number
$offcanvas-size: 250px !default;

/// Height of a top/bottom off-canvas panel.
/// @type Number
$offcanvas-vertical-size: 250px !default;

/// Background color of an off-canvas panel.
/// @type Color
$offcanvas-background: $light-gray !default;

/// Box shadow for the off-canvas panel.
/// @type Shadow
$offcanvas-shadow: 0 0 10px rgba($black, 0.7) !default;

/// Z-index of an off-canvas panel with the `push` transition.
/// @type Number
$offcanvas-push-zindex: 1 !default;

/// Z-index of an off-canvas panel with the `overlap` transition.
/// @type Number
$offcanvas-overlap-zindex: 10 !default;

/// Z-index of an off-canvas panel using the `reveal-for-*` classes or mixin.
/// @type Number
$offcanvas-reveal-zindex: 1 !default;

/// Length of the animation on an off-canvas panel.
/// @type Number
$offcanvas-transition-length: 0.5s !default;

/// Timing function of the animation on an off-canvas panel.
/// @type Keyword
$offcanvas-transition-timing: ease !default;

/// If `true`, a revealed off-canvas will be fixed-position, and scroll with the screen.
/// @type Bool
$offcanvas-fixed-reveal: true !default;

/// Background color for the overlay that appears when an off-canvas panel is open.
/// @type Color
$offcanvas-exit-background: rgba($white, 0.25) !default;

/// CSS class used for the main content area. The off-canvas mixins use this to target the page content.
$maincontent-class: 'off-canvas-content' !default;

/// Adds baseline styles for off-canvas. This CSS is required to make the other pieces work.
@mixin off-canvas-basics {
  // Hides overflow on body when an off-canvas panel is open.
  .is-off-canvas-open {
    overflow: hidden;
  }

  // Off-canvas overlay (generated by JavaScript)
  .js-off-canvas-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transition: opacity $offcanvas-transition-length $offcanvas-transition-timing, visibility $offcanvas-transition-length $offcanvas-transition-timing;

    background: $offcanvas-exit-background;

    opacity: 0;
    visibility: hidden;

    overflow: hidden;

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }

    &.is-closable {
      cursor: pointer;
    }

    &.is-overlay-absolute {
      position: absolute;
    }
    
    &.is-overlay-fixed {
      position: fixed;
    }
  }
}

// Adds basic styles for an off-canvas wrapper.
@mixin off-canvas-wrapper() {
  position: relative;
  overflow: hidden;
}

/// Adds basic styles for an off-canvas panel.
@mixin off-canvas-base(
  $background: $offcanvas-background,
  $transition: $offcanvas-transition-length $offcanvas-transition-timing,
  $fixed: true
) {
  @include disable-mouse-outline;

  @if $fixed == true {
    position: fixed;
  }
  @else {
    position: absolute;
  }

  z-index: $offcanvas-push-zindex;

  transition: transform $transition;
  backface-visibility: hidden;

  background: $background;

  // Overlap only styles.
  &.is-transition-overlap {
    z-index: $offcanvas-overlap-zindex;

    &.is-open {
      box-shadow: $offcanvas-shadow;
    }
  }

  // Sets transform to 0 to show an off-canvas panel.
  &.is-open {
    transform: translate(0, 0);
  }
}

/// Adds styles to position an off-canvas panel to the left/right/top/bottom.
@mixin off-canvas-position(
  $position: left,
  $orientation: horizontal,
  $size: if($orientation == horizontal, $offcanvas-size, $offcanvas-vertical-size)
) {
  @if $position == left {
    top: 0;
    left: 0;
    width: $size;
    height: 100%;

    transform: translateX(-$size);
    overflow-y: auto;

    // Sets the open position for the content
    &.is-open ~ .#{$maincontent-class} {
      transform: translateX($size);
    }
  }
  @else if $position == right {
    top: 0;
    right: 0;
    width: $size;
    height: 100%;

    transform: translateX($size);
    overflow-y: auto;

    // Sets the open position for the content
    &.is-open ~ .#{$maincontent-class} {
      transform: translateX(-$size);
    }
  }
  @else if $position == top {
    top: 0;
    left: 0;

    width: 100%;
    height: $size;

    transform: translateY(-$size);
    overflow-x: auto;

    // Sets the open position for the content
    &.is-open ~ .#{$maincontent-class} {
      transform: translateY($size);
    }
  }
  @else if $position == bottom {
    bottom: 0;
    left: 0;

    width: 100%;
    height: $size;

    transform: translateY($size);
    overflow-x: auto;

    // Sets the open position for the content
    &.is-open ~ .#{$maincontent-class} {
      transform: translateY(-$size);
    }
  }

  // If $offcanvas-shadow is set, add it as a pseudo-element.
  // This mimics the off-canvas panel having a lower z-index, without having to have one.
  @if $offcanvas-shadow {
    &.is-transition-push::after {
      position: absolute;

      @if $position == left {
        top: 0;
        right: 0;

        height: 100%;
        width: 1px;
      }
      @else if $position == right {
        top: 0;
        left: 0;

        height: 100%;
        width: 1px;
      }
      @else if $position == top {
        bottom: 0;
        left: 0;

        height: 1px;
        width: 100%;
      }
      @else if $position == bottom {
        top: 0;
        left: 0;

        height: 1px;
        width: 100%;
      }

      box-shadow: $offcanvas-shadow;
      content: " ";
    }
  }

  // No transform on overlap transition
  &.is-transition-overlap.is-open ~ .#{$maincontent-class} {
    transform: none;
  }
}

/// Sets the styles for the content container.
@mixin off-canvas-content() {
  transition: transform $offcanvas-transition-length $offcanvas-transition-timing;
  backface-visibility: hidden;
}

/// Adds styles that reveal an off-canvas panel.
@mixin off-canvas-reveal(
$position: left,
$zindex: $offcanvas-reveal-zindex,
$content: $maincontent-class
) {
  transform: none;
  z-index: $zindex;

  @if not $offcanvas-fixed-reveal {
    position: absolute;
  }

  & ~ .#{$content} {
    margin-#{$position}: $offcanvas-size;
  }
}

@mixin foundation-off-canvas {
  @include off-canvas-basics;

  // Off-canvas wrapper
  .off-canvas-wrapper {
    @include off-canvas-wrapper;
  }

  // Off-canvas container
  .off-canvas {
    @include off-canvas-base;
  }

  // Off-canvas container with absolute position
  .off-canvas-absolute {
    @include off-canvas-base($fixed: false);
  }

  // Off-canvas position classes
  .position-left    { @include off-canvas-position(left,   horizontal); }
  .position-right   { @include off-canvas-position(right,  horizontal); }
  .position-top     { @include off-canvas-position(top,    vertical); }
  .position-bottom  { @include off-canvas-position(bottom, vertical); }

  .off-canvas-content {
    @include off-canvas-content;
  }

  // Reveal off-canvas panel on larger screens
  @each $name, $value in $breakpoint-classes {
    @if $name != $-zf-zero-breakpoint {
      @include breakpoint($name) {
        .position-left.reveal-for-#{$name} {
          @include off-canvas-reveal(left);
        }

        .position-right.reveal-for-#{$name} {
          @include off-canvas-reveal(right);
        }

        .position-top.reveal-for-#{$name} {
          @include off-canvas-reveal(top);
        }

        .position-bottom.reveal-for-#{$name} {
          @include off-canvas-reveal(bottom);
        }
      }
    }
  }
}

